class LifeVineAssignments {
    private lv: LifeVine;
    private basePath: string;

    constructor(lv: LifeVine) {
        this.lv = lv;
    }

    public pass(path: string) {
        this.basePath = path + '/assignments';
        return this;
    }

    public get(id?: number, options?: LifeVineAssignmentsOptions) {
        let promise;
        if (options) {
            promise = this.lv.ajax('GET', this.getPath(id), options);
        } else {
            promise = this.lv.ajax('GET', this.getPath(id));
        }
        return promise;
    }

    public create(data) {
        return this.lv.ajax('POST', this.getPath(), data);
    }

    public save(id: number, data) {
        return this.lv.ajax('PUT', this.getPath(id), data);
    }

    private getPath(id?: number|null) {
        return id ? `${this.basePath}/${id}` : this.basePath;
    }
}

interface LifeVineAssignmentsOptions {
    status?: number[];
}